import React from "react";
import { Navbar, NavDropdown, ToggleButton } from "react-bootstrap";
import beats from "../img/BSTARBLACK.png";

export default function Nav() {
  function classToggle() {
    const navbarLinks = document.getElementsByClassName('navbar-links')[0]
    navbarLinks.classList.toggle('active')
  }

  return (
    <nav className="navbar">
      <h1 className="brand-title">AWAKE</h1>
      <a href="#" className="toggle-button" onClick={classToggle}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </a>
      <div className="navbar-links">
        <ul>
          <li> <a href="https://www.youtube.com/channel/UCBCFGDhDbSUBqANQ6p4A9kA" target="_blank">
   <i className="fab fa-youtube mx-3 nav-icons" id="youtube-icon"></i>
 </a></li>
          <li> <a href="https://open.spotify.com/artist/0tP4YHogjb78sCo359uPrH?si=t5TZViJPR7-vSF0jMiN-bQ&fbclid=IwAR0A4SATOgU267bJDovYf1FYpZviDOz2Dv76gEquZrPG6NmizCxZja4C_M8&nd=1" target="_blank">
   <i className="fab fa-spotify mx-3 nav-icons" id="spotify-icon"></i>
</a></li>
          <li> <a href="https://www.beatstars.com/awakeofficial/feed?fbclid=IwAR2PUNA73gDRmP_6lY_YXX-cSzAfqpTtOW5fFIDeKskajMcYqWtGjWbzs9c" target="_blank">
   <img src={beats} alt="cover" className="nav-icons mx-3 mb-2" height="40px" id="bstar-img"></img>
   </a></li>
   <a href="https://soundcloud.com/user-33526409?ref=clipboard&p=i&c=1&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" target="_blank">
   <i className="fab fa-soundcloud mx-3 mt-3 nav-icons" height="40px" id="soundcloud-icon"></i>
 </a>
 <li> <a href="https://music.apple.com/ca/artist/awake/1513441235?fbclid=IwAR2Y__7IMxpPTQAwZQZgi1BrX_gjvp2E5sruvasKU6-T8NyaDqRKwFZMhNo" target="_blank"><i className="fab mb-2 fa-apple mx-3 nav-icons text-dark"></i></a></li>
        </ul>
      </div>
    </nav>
  );
}

// <nav className="d-flex justify-content-center  align-items-center">

//  <a href="https://www.beatstars.com/awakeofficial/feed?fbclid=IwAR2PUNA73gDRmP_6lY_YXX-cSzAfqpTtOW5fFIDeKskajMcYqWtGjWbzs9c" target="_blank">
//    <img src={beats} alt="cover" className="nav-icons mx-3 mb-2" height="40px" id="bstar-img"></img>
//    </a>
//    <a href="https://music.apple.com/ca/artist/awake/1513441235?fbclid=IwAR2Y__7IMxpPTQAwZQZgi1BrX_gjvp2E5sruvasKU6-T8NyaDqRKwFZMhNo" target="_blank"><i className="fab mb-2 fa-apple mx-3 icons text-dark"></i></a>
//  <a href="https://soundcloud.com/user-33526409?ref=clipboard&p=i&c=1&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" target="_blank">
//    <i className="fab fa-soundcloud mx-3 nav-icons" id="soundcloud-icon"></i>
//  </a>

// <h1 id="brand">AWAKE</h1>

//  <a href="https://www.youtube.com/channel/UCBCFGDhDbSUBqANQ6p4A9kA" target="_blank">
//    <i className="fab fa-youtube mx-3 nav-icons" id="youtube-icon"></i>
//  </a>
//  <a href="https://open.spotify.com/artist/0tP4YHogjb78sCo359uPrH?si=t5TZViJPR7-vSF0jMiN-bQ&fbclid=IwAR0A4SATOgU267bJDovYf1FYpZviDOz2Dv76gEquZrPG6NmizCxZja4C_M8&nd=1" target="_blank">
//    <i className="fab fa-spotify mx-3 nav-icons" id="spotify-icon"></i>
// </a>
// <div className="collapsed-nav"></div>
//  <ul>
//    <li>ss</li>
//  </ul>
// <div className="toggler d-flex justify-content-end">
//    <i className="fas fa-bars d-flex justify-content-end"></i>
// </div>

// </nav>
