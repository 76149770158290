import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import CoverIMG from "../img/single_cover.jpg";
import beats from "../img/BSTAR.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  return (
    <header>
      <Container
        fluid
        className="h-100 d-flex justify-content-center text-center flex-column"
      >
        <Row className="d-flex justify-content-center align-items-center mb-5">
          <Col md="12">
            <h1 id="last-release">Dernier release</h1>
            <hr className="bg-light w-50 mx-auto"></hr>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center">
          <Col md="6" className="d-flex justify-content-md-end justify-content-center">
            <img src={CoverIMG} alt="cover" height="250px" id="cover-img"></img>
          </Col>
          <Col md="6" className="d-flex justify-content-md-start justify-content-center">            
            <div className="d-flex flex-column">
            <h1 id="title" className="mb-5 mt-3">
              Gone
            </h1>
            <div className="loader mb-5 mx-auto">
              <span className="stroke"></span>
              <span className="stroke"></span>
              <span className="stroke"></span>
              <span className="stroke"></span>
              <span className="stroke"></span>
              <span className="stroke"></span>
              <span className="stroke"></span>
            </div>

            <div className="social-icons">
            <a href="https://www.beatstars.com/awakeofficial/feed?fbclid=IwAR2PUNA73gDRmP_6lY_YXX-cSzAfqpTtOW5fFIDeKskajMcYqWtGjWbzs9c" target="_blank">
              <img
                src={beats}
                alt="cover"
                height="45px"
                width="45px"
                className="mb-5 mx-3 icons"
                id="bstar-img"
              ></img>
              </a>
               <a href="https://soundcloud.com/user-33526409/awake-gone-master-3-wav" target="_blank">
              <i
                className="fab fa-soundcloud mx-3 icons"
                id="soundcloud-icon"
              ></i></a>
                {/* <a href="https://www.youtube.com/channel/UCBCFGDhDbSUBqANQ6p4A9kA" target="_blank">
              <i className="fab fa-youtube mx-3 icons" id="youtube-icon"></i>
              </a> */}
              <a href="https://music.apple.com/ca/album/gone-feat-catherine-van-doesburg-single/1560745607" target="_blank" id="apple"><i className="fab fa-apple mx-3 icons"></i></a>
              <a href="https://open.spotify.com/artist/0tP4YHogjb78sCo359uPrH?si=t5TZViJPR7-vSF0jMiN-bQ&fbclid=IwAR0A4SATOgU267bJDovYf1FYpZviDOz2Dv76gEquZrPG6NmizCxZja4C_M8&nd=1" target="_blank">
              <i className="fab fa-spotify mx-3 icons" id="spotify-icon"></i>
              </a>
            </div>
            </div>
          </Col>
        </Row>

        {/* <Row className="d-flex justify-content-center align-items-center">
          <Col md={{span: 6}} sm="12"
            className="d-flex flex-row-reverse justify-content-start justify-content-sm-center mr-auto"
          >
            <img src={CoverIMG} alt="cover" height="250px"></img>
          </Col>
          <Col md={{span: 6}} sm="12" className="d-flex justify-content-end flex-column flex-row-reverse justify-content-sm-center">            
            <div className="d-flex flex-column mr-auto">
            <h1 id="title" className="mb-5">
              Gone
            </h1>
            <div className="loader mb-5 mx-auto">
              <span className="stroke"></span>
              <span className="stroke"></span>
              <span className="stroke"></span>
              <span className="stroke"></span>
              <span className="stroke"></span>
              <span className="stroke"></span>
              <span className="stroke"></span>
            </div>

            <div className="social-icons">
              <img
                src={beats}
                alt="cover"
                height="50px"
                width="50px"
                className="mb-4 mx-auto"
              ></img>
              <i
                className="fab fa-soundcloud mx-3 icons"
                id="soundcloud-icon"
              ></i>
              <i className="fab fa-youtube mx-3 icons" id="youtube-icon"></i>
              <i className="fab fa-spotify mx-3 icons" id="spotify-icon"></i>
            </div>
            </div>
          </Col>
        </Row> */}
      </Container>
      {/* <p id="head1" className="header">
          Awesome designs
        </p>
        <p id="head2" className="header">
          Just
        </p>
        <p id="head3" className="header">
          For you
        </p>
        <p id="head4" className="header">
          simple and awesome all the time
        </p>
        <p id="head5" className="header">
          Welcome to BA designs
        </p>
        <button>Continue</button> */}
      <div className="light x1"></div>
      <div className="light x2"></div>
      <div className="light x3"></div>
      <div className="light x4"></div>
      <div className="light x5"></div>
      <div className="light x6"></div>
      <div className="light x7"></div>
      <div className="light x8"></div>
      <div className="light x9"></div>
    </header>
  );
}
