import React from "react";
import Header from './Header.js'
import Nav from './Nav.js'

function App() {
 
  // React.useEffect(()=>{
  //   const el = document.querySelector('header')
  //   el.addEventListener('mousemove', e =>{
  //      function parallax(e){
  //        const speed = -1;

  //        const x = (window.innerWidth - e.pageX + 20*speed)/250
  //        const y=  (window.innerHeight - e.pageY + 20*speed)/200

  //       //  el.style.backgroundPositionX = x + 'px'
  //       //  el.style.backgroundPositionY = y + 'px'
  //        el.style.transform = `translateX(${x}px) translateY(${y}px)`
  //      }
  //      parallax(e)
  //   })
  // },[])


  return (
    <>

    <div className="App">
    <Header />
    </div>
    </>
  );
}

export default App;
